<template>
  <div class="main">
    <!-- 轮播图模块 -->
    <BookletWrapper class="home-wrapper"> </BookletWrapper>
    <div id="home">
      <section class="left">
    <!-- 去碳小知识 -->
<div class="box-global knowledge">
  <h2 class="label">去碳小知识</h2>
  <div class="knowledge-card">
    <Cards class="card" v-for="item in KnowData2" :key="item.blog_id" @click.native="goDetail2(item)">
      <img v-lazy="item.cover_image" slot="media" />
      <p slot="title" class="content">{{ item.blog_title }}</p>
    </Cards>
  </div>
</div>
  </section>
      <section class="right">

        <div class="box-global user-info" v-if="!isLogin">
          <h2 class="label">知识联盟</h2>
          <div class="join-us">
            <li v-for="item in linkData">{{ item.name }}</li>
<!--            <li v-for="item in linkData" :key="item.id" @click="goLink(item)">{{ item.name }}</li>-->
          </div>
        </div>

<!--        <div class="box-global user-info" v-else>-->
<!--          <div class="info-top">-->
<!--            <li class="avatar">-->
<!--              <el-avatar :src="userInfo.user_avatar"></el-avatar>-->
<!--              <section class="info">-->
<!--                <p class="name">{{ userInfo.user_name }}</p>-->
<!--                <p><span class="c-coin">C币:</span> {{ userInfo.user_score }}</p>-->
<!--              </section>-->
<!--            </li>-->
<!--            <li>-->
<!--              <ClickButton @click.native="goAction()">今日打卡</ClickButton>-->
<!--            </li>-->
<!--          </div>-->

<!--        </div>-->
        <!-- 排行榜 -->
<!-- 排行榜 -->
<div class="box-global">
  <h2 class="label">最新咨询</h2>
  <div class="consult-list">
    <div v-for="item in consultData" :key="item.content" @click="goConsult(item)">

      <p>{{ item.content }}</p>
    </div>
  </div>
</div>
      </section>
    </div>
    <!-- 视频模块 -->
    <div class="box-global video-card">
      <h2 class="label">碳知识小视频</h2>
      <div class="video">
        <HoverCard class="medium" v-for="item in videoData" :key="item.blog_id">
          <video muted loop controls webkit-playsinline="true" playsinline="true" x5-video-player-type="h5-page" :poster="item.cover_image" class="videoItem" slot="media"></video>
          <h5 slot="title">{{ item.blog_title }}</h5>
          <h5 slot="info-title">{{ item.blog_title }}</h5>
          <ClickButton slot="btn" @click.native="goDetail(item)">探索更多</ClickButton>
        </HoverCard>
      </div>
    </div>
  </div>
</template>

<script>

  import BookletWrapper from './components/BookletWrapper'
  import ClickButton from '@/components/ClickButton'
  import Cards from '@/components/Cards'
  import RankList from '@/components/RankList'
  import HoverCard from '@/components/HoverCard'

  export default {
    name: '',
    components: {
      BookletWrapper,
      ClickButton,
      Cards,
      RankList,
      HoverCard,
    },
    data() {
      return {
        rank: 0,
        count: 0,
        queryInfo: {
          type_name: '知识',
          limit: 6,
        },
consultData: [
  {  content: '"“双碳”落向实处  “绿色”引领发展"', link: 'https://mp.weixin.qq.com/s?__biz=MzA3ODQyOTMxMA==&mid=2650778685&idx=1&sn=b24da17fbcecb81c32fb90e04ed567de&chksm=8632d7900bd3fbc58964d7ab6fcb4e3e69193bdd27374f0daac27700ee27cc77d295e0829ee8&scene=27' },
  {  content: '"向“绿”而行 “碳”寻经济高质量发展"', link: 'http://www.gd.xinhuanet.com/20240129/1118e66b6c7e4383ac312f21fd611c5a/c.html' },
  {  content: '"经济第一大省解好绿色发展“算术题”"', link: 'https://baijiahao.baidu.com/s?id=1795291523933805191&wfr=spider&for=pc' },
     {  content: '"构建新型能源体系 助力如期实现碳达峰碳中和"', link: 'https://drc.gd.gov.cn/qtwj/content/post_4137543.html' },
     {  content: '"积极稳妥推进碳达峰碳中和"', link: 'https://www.gov.cn/yaowen/2023-04/06/content_5750183.htm' },
     {  content: '"建设人与自然和谐共生的美丽家园"', link: 'https://baijiahao.baidu.com/s?id=1777738879289235099&wfr=spider&for=pc' },
     {  content: '"中国能源安全和碳中和，面临哪些机遇与难点？"', link: 'https://baijiahao.baidu.com/s?id=1748431191523920655&wfr=spider&for=pc' },
     {  content: '"碳中和：中国的绿色革命"', link: 'https://baijiahao.baidu.com/s?id=1781259560977351960&wfr=spider&for=pc' },
     {  content: '"极端天气频发，碳达峰、碳中和背景下的城市韧性安全"', link: 'https://baijiahao.baidu.com/s?id=1799193427368508495&wfr=spider&for=pc' },
    {  content: '"完整准确全面贯彻新发展理念 扎实做好碳达峰碳中和工作"', link: 'https://www.gov.cn/xinwen/2021-10/25/content_5644800.htm' },
    {  content: '"我国碳达峰碳中和战略及路径"', link: 'https://baike.baidu.com/item/%E6%88%91%E5%9B%BD%E7%A2%B3%E8%BE%BE%E5%B3%B0%E7%A2%B3%E4%B8%AD%E5%92%8C%E6%88%98%E7%95%A5%E5%8F%8A%E8%B7%AF%E5%BE%84/60661180?fr=aladdin' },


  // 添加更多咨询数据
],

        //
        linkData: [
          { id: 0, name: '加入我们', path: '/login' },
          { id: 1, name: '碳中和足迹', path: '/echart' },
          { id: 2, name: '碳中和文库', path: '/library' },
          { id: 3, name: '碳中和活动', path: '/actions' },
          { id: 4, name: '碳中和组织', path: '/organization' },
          { id: 5, name: '与我们联系', path: '/feedback' },
        ],
     //    // 用户信息
     //    userInfo: this.$store.state.user.user,
     //    info: {
     //      like: 45,
     //      collect: 46,
     //      rank: 1950,
     //      total: 1236,
     // },

        //去碳小视频数据
        videoData: [
          {
            blog_id: 0,
            cover_image: require('@/assets/images/bg15.png'),
            blog_title: '碳足迹可不是“脚印”',
            video:  require('@/assets/viedos/1-“碳中和科普动画”第三集：碳足迹可不是“脚印”-1080P 高清-AVC.mp4'),
          },
          {
            blog_id: 1,
            cover_image: require('@/assets/images/bg16.png'),
            blog_title: '什么是“双碳”目标？',
            video: require('@/assets/viedos/1-“碳中和科普动画”第二集：什么是“双碳”目标？-1080P 高清-AVC.mp4'),
          },
          {
            blog_id: 2,
            cover_image: require('@/assets/images/bg17.png'),
            blog_title: '“碳达峰”、“碳中和”科普',
            video: require('@/assets/viedos/1-【科普动画】“碳达峰”、“碳中和”科普知识动画宣传片-1080P 高清-AVC.mp4'),
          },
          {
            blog_id: 3,
            cover_image: require('@/assets/images/bg18.png'),
            blog_title: '碳中和背后的经济博弈',
            video: require('@/assets/viedos/1-中国为什么一定要走碳中和之路？碳中和背后的经济博弈！-1080P 高清-AVC.mp4'),
          },
          {
            blog_id: 4,
            cover_image: require('@/assets/images/bg19.png'),
            blog_title: '关于碳达峰碳中和',
            video: require('@/assets/viedos/1-关于碳达峰碳中和 你该了解的九件事-1080P 高清-AVC.mp4'),
          },
          {
            blog_id: 5,
            cover_image: require('@/assets/images/bg20.png'),
            blog_title: '什么是碳中和、碳达峰？',
            video: require('@/assets/viedos/第3期 什么是碳中和、碳达峰？.mp4'),
          },
        ],
        KnowData2: [
          {
            blog_id: 0,
            cover_image: 'https://qucs.oss-cn-hangzhou.aliyuncs.com/images/2.jpg',
            blog_title: '科普君：碳达峰和碳中和究竟是什么？',
            link:'https://zhidao.baidu.com/question/1967727055049390500/answer/3991848831.html?fr=zywd'
          },
                      {
            blog_id: 1,
            cover_image: 'https://qucs.oss-cn-hangzhou.aliyuncs.com/images/0.jpg',
            blog_title: '我国碳达峰碳中和战略及路径',
            link:'https://baike.baidu.com/item/%E6%88%91%E5%9B%BD%E7%A2%B3%E8%BE%BE%E5%B3%B0%E7%A2%B3%E4%B8%AD%E5%92%8C%E6%88%98%E7%95%A5%E5%8F%8A%E8%B7%AF%E5%BE%84/60661180?fr=ge_ala'
          },
                      {
            blog_id: 2,
            cover_image: 'https://qucs.oss-cn-hangzhou.aliyuncs.com/images/3.jpg',
            blog_title: '什么是双碳',
            link:'https://baike.baidu.com/item/%E5%8F%8C%E7%A2%B3/57996712?fr=ge_ala'
          },
                      {
            blog_id: 3,
            cover_image: 'https://qucs.oss-cn-hangzhou.aliyuncs.com/images/4.jpg',
            blog_title: '未来的低碳生活场景',
            link:'https://baijiahao.baidu.com/s?id=1786392882266289585&wfr=spider&for=pc'
          },
                      {
            blog_id: 4,
            cover_image: 'https://qucs.oss-cn-hangzhou.aliyuncs.com/images/5.jpg',
            blog_title: '你不知到的碳排放',
            link:'https://business.sohu.com/a/722329041_119659'
          },
                      {
            blog_id: 5,
            cover_image: 'https://qucs.oss-cn-hangzhou.aliyuncs.com/images/6.jpg',
            blog_title: '碳达峰和碳中和是个啥？',
            link:'https://baijiahao.baidu.com/s?id=1688905347661851958&wfr=spider&for=pc'
          },
          ],
      }
    },
    methods: {
goConsult(item) {
    if (item.link.startsWith("http://") || item.link.startsWith("https://")) {
        window.open(item.link, '_blank');
    } else {
        window.open("http://" + item.link, '_blank');
    }
},
        goDetail2(item) {
    window.open(item.link, '_blank');
  },

      // 跳转到详细页面
goDetail(item) {
  if (item.video) {
    // 如果存在视频链接，则播放视频
    window.open(item.video); // 在新标签页打开视频链接
  } else if (item.link) {
    // 如果存在普通链接，则跳转到指定链接
    window.location.href = item.link;
  } else {
    alert("No link provided for this item");
  }
},
      // 跳转到各个小界面
      goLink(item) {
        this.$router.push(item.path)
      },
      // 跳转到活动界面
      goAction() {
        this.$router.push('/actions')
      },
      // 获取知识卡片
      async getBlog() {
        this.knowData = await this.$store.dispatch('getBlog', this.queryInfo)
      },
    },
    created() {
      this.getBlog()
    },
    computed: {
      isLogin() {
        console.log(this.$store.state.user.user)
        return this.$store.state.user.user
      },
    },
  }
</script>

<style scoped lang="less">
  .main {
    padding: 10px 30px;
    #home {
      margin-top: 19px;
      display: flex;
      .left {
        flex: 2;
        .knowledge {
          width: 100%;
          .knowledge-card {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .card {
              overflow: hidden;
              width: 32%;
              height: 76px;
              margin-bottom: 4.42px;
              img {
                height: 50px;
                width: 100%;
                transition: all 0.5s linear;
              }
              &:hover {
                img {
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }
      .right {
        flex: 1;
        padding-left: 6px;
        .punch-card {
          display: flex;
          justify-content: space-around;
          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
        .user-info {
          height: 37px;
          .info-top {
            display: flex;
            justify-content: space-around;
            .avatar {
              display: flex;
              .info {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 13px;
                margin-left: 5px;

                .name {
                  font-weight: 600;
                }
                .c-coin {
                  font-size: 3.7px;
                  font-weight: 600;
                }
              }
            }
          }
          .info-bottom {
            li {
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin-top: 3px;
              span {
                flex: 1;
                font-size: 3.7px;
                text-align: center;
              }
            }
          }
        }
        .join-us {
          width: 100%;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          li {
            width: 30%;
            height: 9px;
            margin-bottom: 2px;
            color: #fff;
            font-size: 4.2px;
            font-weight: 600;
            line-height: 9px;
            text-align: center;
            border-radius: 2px;
            background: linear-gradient(90deg, #cbee41, #0dbc79);
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
              transform: translateY(-1.7px);
              box-shadow: 2px 2px 7px #dbd9e9;
            }
          }
        }
      }
    }
    // 视频卡片
    .video-card {
      margin-top: 6px;
      .video {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .medium {
          height: 47px;
          width: 32%;
          margin-bottom: 6px;
        }
      }
    }
  }

  // 头像的大小
  .el-avatar {
    width: 13px;
    height: 13px;
  }
  // 定义字体的大小
  p {
    font-size: 4.2px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      color: #0dbc79;
    }
  }
  .consult-list p {
  line-height: 2.1 /* 1.5倍行高，根据需求调整 */
}
</style>
